const objectIsEmpty = object => object.constructor === Object && Object.entries(object).length === 0;

const isValidCoordinate = ({ value, coordinatesTypes, col }) => {
  if (value === '') {
    return true ;
  }

  if (coordinatesTypes === 'LAT-LNG' && /^[-]?([1-9]\d*|0)(,\d+)?$/.test(value)) {
    const valueNumber = parseFloat(formatCoordinateToUser({ value, coordinatesTypes }));

    if (col === 1) {
      return valueNumber >= -90 && valueNumber <= 90 ? true : false;
    } else if (col === 2) {
      return valueNumber >= -180 && valueNumber <= 180 ? true : false;
    }
  } else if (coordinatesTypes === 'WSG84') {
    const formatValue = formatCoordinateToUser({ value, coordinatesTypes });

    return /^([1-9]\d*|0)(,\d+)?$/.test(formatValue) ? true : false;
  }
  if (coordinatesTypes === 'PSAD56') {
    return /^(([1-9])(\d+)?|0)(\.(\d+))?$/.test(formatCoordinateToUser({ value, coordinatesTypes }));
  }
  return false;
};

const formatCoordinateToUser = ({ value, coordinatesTypes }) => {
  if (coordinatesTypes === 'WSG84') {
    return value.replace(/\./g, '');
  } else if (coordinatesTypes === 'LAT-LNG') {
    return value.replace(/,/g, '.');
  } else if (coordinatesTypes === 'PSAD56') {
    return value.replace(/\./g, '').replace(/,/g, '.');
  }

  return value;
};

const formatCoordinateToFloatNumber = value => parseFloat(value.replace(/,/g, '.'));

const typeGeoJson = {
  POINT: 'Point',
  LINESTRING: 'LineString',
  POLYGON: 'Polygon',
};

const pointToGeoPoint = ({ simpleGeometry = false, lat, lon, ...props }) => simpleGeometry ?
  { type: 'Point', coordinates: [ lon, lat ] } :
  ({
    type: 'Feature',
    properties: props,
    geometry: { type: 'Point', coordinates: [ lon, lat ] },
  });

const stopSendingOnEnter = e => {
  const enter = 13;
  const keyCode = e.key ? e.key : e.keyCode ? e.keyCode : e.which;
  if (keyCode === enter || keyCode === 'Enter') {
    e.preventDefault();
  }
};

const pointsToLineStringFeats = points =>
  // expects an array like [{name: "pointName", x:1, y: 2}, {name: "pointName", x:2, y: 3},
  //   {name: "Other pointName", x:11, y: 22}, {name: "Other pointName", x:12, y: 20}]
  // where points of the same name belong to the same LineString feature.
  // Note that if you pass a single-point-line, this will not clean that up
  points.reduce((acc, curr) => {
    let latestInd = acc.length - 1;
    if (acc[latestInd]?.properties.name !== curr.name) {
      acc.push({
        type: 'Feature',
        properties: { name: curr.name },
        geometry: {
          type: 'LineString',
          coordinates: [],
        },
      });
      latestInd++;
    }
    acc[latestInd].geometry.coordinates.push([ curr.x, curr.y ]);
    return acc;
  }, []);

const isValidLLNumber = (number, col, options = {}) => {
  const { throwError = false } = options;
  if (Number.isNaN(number) || typeof number !== 'number') {
    if (throwError) {
      throw Error('Invalid Lat-Lng number!');
    }
    return false;
  }
  if (col === 1) {
    return number >= -90 && number <= 90;
  } else if (col === 2) {
    return number >= -180 && number <= 180;
  }
};

const isInvalidCoord = coord => Number.isNaN(coord) || typeof coord !== 'number';
const isInvalidPoint = point => isInvalidCoord(point[0]) && isInvalidCoord(point[1]);
const isInvalidLine = line => line.some(isInvalidPoint);
const isInvalidPolygon = polygon => polygon.some(isInvalidLine);


/**
 * Retorna true si la feature o geometría dada es inválida.
 * referencias: https://www.ibm.com/docs/en/db2/11.5?topic=formats-geojson-format, https://stevage.github.io/geojson-spec/#section-3
 * @param { objecto } featureOrGeometry: la feature o geometría.
 * @param { object } options: opciones extra:
 *   allowMulti { boolean }, false por defecto: dice si considera multi como válidas o no.
 *   validTypes { array }, null por defecto: se puede dar como un array de strings de nombres de tipo, y si el tipo de geometría no está
 *     en ese array, entonces se considerará inválida
 * @return { boolean }
 */
const isInvalidGeometry = (featureOrGeometry, options = {}) => {
  const { allowMulti = false, validTypes = null } = options;
  // en el caso de "geometries" en verdad no son coordinates si no que una lista de geometrías y cada una de esa tiene coordiantes
  const coordinates = featureOrGeometry?.geometry?.coordinates || featureOrGeometry?.geometry?.geometries || featureOrGeometry.coordinates;
  const type = featureOrGeometry?.geometry?.type || featureOrGeometry.type;

  if (validTypes && !validTypes.includes(type)) {
    return true;
  } else if (type === 'Point') {
    return isInvalidPoint(coordinates);
  } else if (type === 'LineString') {
    return isInvalidLine(coordinates);
  } else if (type === 'Polygon') {
    return isInvalidPolygon(coordinates);
  } else if (type === 'GeometryCollection') {
    return coordinates.some(isInvalidGeometry);
  } else if (allowMulti) {
    if (type === 'MultiPoint') {
      for (const pointCoords of coordinates) {
        if (isInvalidPoint(pointCoords)) {
          return true;
        }
      }
    } else if (type === 'MultiLineString') {
      for (const lineCoords of coordinates) {
        if (isInvalidLine(lineCoords)) {
          return true;
        }
      }
    } else if (type === 'MultiPolygon') {
      for (const polyCoords of coordinates) {
        if (isInvalidPolygon(polyCoords)) {
          return true;
        }
      }
    }
  } else {
    return true;
  }
};


const isPolygonType = geo => geo.type.includes('Polygon');

const filterNonPolygonsFromGeoCollection = geoCollection => {
  const polyGeometries = geoCollection.geometries?.filter(isPolygonType);
  if (polyGeometries?.length > 0) {
    return { ...geoCollection, geometries: polyGeometries };
  }
  return null;
};

const filterNonPolygonFeatureOrGeometry = geoFeat => {
  const validTopLevelTypes = [ 'Feature', 'GeometryCollection', 'MultiPolygon', 'Polygon' ];
  if (!validTopLevelTypes.includes(geoFeat.type)) {
    return { geoJson: null, changed: true };
  }

  const geom = geoFeat.type === 'Feature' ? geoFeat.geometry : geoFeat;

  if (geom.type.includes('Polygon')) {
    return { geoJson: geoFeat, changed: false };
  } else if (geom.type !== 'GeometryCollection') {
    return { geoJson: null, changed: true };
  }

  // la cosa es una GeometryCollection
  const finalCollection = filterNonPolygonsFromGeoCollection(geom);
  if (finalCollection === null) {
    return { geoJson: null, changed: true };
  }
  const changed = finalCollection.geometries.length !== geom?.geometries.length;
  return { geoJson: finalCollection, changed };
};

const filterNonPolygonsFromGeoJson = geoJson => {
  if (geoJson.type !== 'FeatureCollection') {
    return filterNonPolygonFeatureOrGeometry(geoJson);
  }

  // es una FeatureCollection:
  const finalFeats = [];
  let globalChanged = false;
  // filtrar no polígonos y marcar que algo cambió si se filtra algo.
  for (const feature of geoJson.features) {
    const { geoJson: geoFeat, changed } = filterNonPolygonFeatureOrGeometry(feature);
    globalChanged ||= changed;
    if (geoFeat) {
      finalFeats.push(geoFeat);
    }
  }

  // retornar null si no quedaron features. retornar la misma cosa si no cambió nada (por si sirve conservar la igualdad referencial).
  // Si cambió algo y la cosa tiene features, armar un nuevo geoJson.
  const finalGeoJson = finalFeats.length === 0 ? null
    : globalChanged ? { ...geoJson, features: finalFeats }
    : geoJson;
  return { geoJson: finalGeoJson, changed: globalChanged };
};

export {
  isInvalidGeometry,
  isValidLLNumber,
  objectIsEmpty,
  isValidCoordinate,
  formatCoordinateToUser,
  formatCoordinateToFloatNumber,
  typeGeoJson,
  pointToGeoPoint,
  stopSendingOnEnter,
  pointsToLineStringFeats,
  isPolygonType,
  filterNonPolygonFeatureOrGeometry,
  filterNonPolygonsFromGeoCollection,
  filterNonPolygonsFromGeoJson,
};