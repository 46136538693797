const baseline = {
  project: {
    key: 'project',
    path: '/app/load-project',
    name: 'Proyecto',
  },
  flora: {
    key: 'flora',
    path: '/app/flora',
    name: 'Flora',
  },
  archeology: {
    key: 'archeology',
    path: '/app/archeology',
    name: 'Arqueología',
  },
  areasOfInfluence: {
    key: 'areas-of-influence',
    path: '/app/areas-of-influence',
    name: 'Áreas de Influencia',
  },
  partsAndWorks: {
    key: 'parts-and-works',
    path: '/app/parts-and-works',
    name: 'Partes y obras',
  },
};


export { baseline };