import React from 'react';
import { Grid, CircularProgress, Typography } from '@material-ui/core';

const Loading = () => (
  <Grid container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
    style={{ minHeight: '100vh' }}>
    <Grid item xs={12}>
      <CircularProgress />
    </Grid>
    <Grid item xs={12}>
      <Typography variant="h5">
        Cargando...
      </Typography>
    </Grid>
  </Grid>
);

export default Loading;