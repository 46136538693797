import { createTheme } from '@material-ui/core/styles';


const palette = {
  common: {
    black: 'rgba(46, 46, 46, 1)',
    white: 'rgba(255, 255, 255, 1)',
  },
  primary: {
    light: 'rgba(138, 195, 255, 1)',
    main: 'rgba(0, 102, 184, 1)', // #2BBFCC
    contrastText: '#fff',
  },
  secondary: {
    light: 'rgba(118, 255, 255, 1)',
    main: 'rgba(47, 214, 229, 1)',
    dark: '#2BBFCC',
    contrastText: '#fff',
  },
  background: {
    paper: 'rgba(255, 255, 255, 1)',
    default: 'rgba(250, 250, 250, 1)',
  },
  error: {
    light: '#e57373',
    main: 'rgba(235, 60, 70, 1)',
    dark: '#d32f2f',
    contrastText: '#fff',
  },
  success: {
    main: '#c0d72e',
  },
  text: {
    primary: 'rgba(46, 46, 46, 1)',
    secondary: 'rgba(108, 108, 108, 1)',
    disabled: 'rgba(171, 171, 171, 1)',
    hint: 'rgba(155, 155, 155, 1)',
  },
};

const drawerWidth = 200;
const drawerDefaultZIndex = 1200;
const commonStyles = {
  zIndex: {
    appBar: drawerDefaultZIndex + 1,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#f4f6f8',
        },
        a: {
          textDecoration: 'none',
        },
        'button.easy-button-button': {
          padding: 0,
        },
      },
    },
    MuiDrawer: {
      root: {
        flexShrink: 0,
        width: drawerWidth,
      },
      paper: {
        width: drawerWidth,
      },
    },
  },
};


const mainTheme = createTheme({ palette, ...commonStyles });

export default mainTheme;