import React from 'react';
import { Grid, Link, Button, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';


const baseUrlProject = id => `https://seia.sea.gob.cl/expediente/xhr_documentos.php?id_expediente=${id}`;

const Project = ({ project, children }) => <>
  <Grid container direction="row" spacing={4}>
    <Grid item xs={8}>
      <Grid item xs={12} sm container direction="column" spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" component="span" color="textSecondary">
            {project.nombre}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" component="span" color="textSecondary">
            Tipo de Proyecto: {project.tipo}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={4}>
      <Grid item xs={12} sm container direction="column" spacing={3}>
        <Grid item>
          <Link href={baseUrlProject(project.id)} underline='none' target="_blank" rel="noreferrer">
            <Button variant="contained">Link al proyecto</Button>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
  {children}
</>;

Project.propTypes = {
  project: PropTypes.object.isRequired,
  children: PropTypes.node,
};


export default Project;