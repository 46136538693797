import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { mdiLayersPlus, mdiTractor } from '@mdi/js';


const LayersPlus = props => <SvgIcon {...props}><path d={mdiLayersPlus} /></SvgIcon>;
const Tractor = props => <SvgIcon {...props}><path d={mdiTractor} /></SvgIcon>;


export {
  LayersPlus,
  Tractor,
};
