import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Typography, TextField } from '@material-ui/core';
import { ProjectYearSelection } from 'src/scenes/LoadProject/components';
import { Alert } from '@material-ui/lab';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import Loading from 'src/components/Loading';
import aoiSchema, { arraySchemaIds } from 'src/scenes/AreasOfInfluence/aoiValidationSchema';
import { Page, Section, Project, DialogWrapper, ReportIssueDialog, LeaveConfirm } from 'src/components';
import { ProjectComponent, AddComponentDialog, RemoveComponentDialog } from 'src/scenes/AreasOfInfluence/components';
import { projectApi, areasOfInfluenceApi, optionsApi } from 'src/services';


const typeKey = 'areas-of-influence';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: 200,
    maxWidth: 300,
  },
  submitButton: {
    display: 'block',
    marginTop: theme.spacing(5),
  },
  comments: {
    width: '100%',
  },
}));


const DialogContents = ({ type, data, actions }) => {
  switch (type) {
    case 'report-issue': {
      return <ReportIssueDialog
        seaProjectId={data.seaProjectId}
        actions={actions}
        type="areas-of-influence"
      />;
    }
    case 'add-component': {
      return <AddComponentDialog
        closeDialog={actions.closeDialog}
        addComponent={actions.addComponent}
        componentList={data.componentList}
        availableOptions={data.availableOptions}
      />;
    }
    case 'remove-component': {
      return <RemoveComponentDialog
        removeComponent={actions.removeComponent}
        closeDialog={actions.closeDialog}
        componentName={data.componentName}
        componentIndex={data.componentIndex}
        dataOnWeb={data.dataOnWeb}
      />;
    }
    default:
      break;
  }
};

DialogContents.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

// mover a un utils? aunque me gusta que esté acá para que se vea en este archivo qué forma tienen todas las partes del formulario...
const getEmptyAreaGeometryGroup = ({ phaseOptions, phaseValue } = {}) => ({
  phases: phaseOptions && phaseValue != null ? [ phaseOptions.find(po => po.value === phaseValue) ] : [],
  locality: '',
  geoJson: null,
  geometriesHaveProblems: false,
  problemsDescription: '',
});

const getEmptyAoIComponent = ({ name, articles, dataOnWeb = false }) => ({
  name,
  articles, // solo usado para mostrar?
  areaGeometryGroups: [ getEmptyAreaGeometryGroup() ], // empieza con una fase abierta y vacía
  dataOnWeb,
});

const AreasOfInfluenceForm = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const { seaProjectId } = match.params;

  const history = useHistory();
  const pushProjectToHistory = seaProject => history.push({
    pathname: `load-project/${seaProject.id}/form`,
    state: { seaProject: seaProject },
  });

  const replaceProjectToHistory = seaProject => history.replace({
    pathname: `load-project/${seaProject.id}/form`,
    state: { seaProject: seaProject },
  });

  const { state: locationState } = useLocation();
  const [ seaProject, setSeaProject ] = useState(locationState?.seaProject);
  const [ sendingData, setSendingData ] = useState(false);
  const [ dialog, setDialog ] = useState({ isOpen: false, type: null, data: null, actions: null });
  const [ mustRestartPage, setMustRestartPage ] = useState(false);

  const availableComponentsRef = useRef({});

  const [ form, setForm ] = useState({
    projectComponents: [],
    comments: '',
  });

  // cosas para prevenir que maten la página por accidente cuando tienen datos no guardados:
  const lastSavedCommentsRef = useRef('');
  const checkLeaveConfirmNeeded = () => !mustRestartPage &&
    (form.comments !== lastSavedCommentsRef.current || form.projectComponents.some(pc => !pc.dataOnWeb));

  const [ loadingProject, setLoadingProject ] = useState(!seaProject);
  const [ loadedFormDataAndOptions, setLoadedFormDataAndOptions ] = useState(false);
  const [ componentList, setComponentList ] = useState([]);
  const [ phaseOptions, setPhaseOptions ] = useState([]);
  const [ projectYear, setProjectYear ] = useState(seaProject?.year ?? null);
  const [ randomProjectNotFound, setRandomProjectNotFound ] = useState(false);

  const closeDialog = useCallback(() => setDialog({ isOpen: false, type: null, data: null, actions: null }), []);
  const restartForm = useCallback(() => setMustRestartPage(true), []);

  useEffect(() => {
    if (mustRestartPage) {
      history.push('/app/areas-of-influence/load-project');
    }
  // eslint-disable-next-line -- history no cambia así que no es necesario ponerlo aquí
  }, [ mustRestartPage ]);

  // TODO: se podrían separar la lista de componentes y phaseOptions y tirar como estado en los history.push para no rehacerlos con cada
  // reinicio?
  useEffect(() => {
    if (seaProjectId) {
      const fetchFormDataAndOptions = async () => {
        try {
          const [ componentList, phaseOptions, currentWork ] = await Promise.all([
            areasOfInfluenceApi.getComponentList(),
            optionsApi.getPhases({ component: 'aoi' }),
            areasOfInfluenceApi.getCurrentWork(seaProjectId),
          ]);

          availableComponentsRef.current = componentList.reduce((acc, comp) => ({ ...acc, [comp.name]: true }), {});

          if (currentWork) {
            const currentComponents = currentWork.components.map(component => {
              availableComponentsRef.current[component.name] = false;
              return getEmptyAoIComponent({ name: component.name, dataOnWeb: true });
            });

            setForm(pf => ({ comments: currentWork.comments, projectComponents: [ ...pf.projectComponents, ...currentComponents ] }));
            lastSavedCommentsRef.current = currentWork.comments;
          }

          setComponentList(componentList);
          setPhaseOptions(phaseOptions);
          setLoadedFormDataAndOptions(true);
        } catch (e) {
          console.log(e);
          toast.error(
            e.serverMessage ?? e.serviceMessage ?? 'Hubo un error al cargas los datos del formulario, por favor intenta más tarde',
          );
        }
      };
      fetchFormDataAndOptions();
    } else {
      getPrioritizedProject();
    }
  // eslint-disable-next-line
  }, [ seaProjectId ]);

  // TODO: estado de error y mensaje de error para esto
  useEffect(() => {
    if (seaProjectId && !seaProject) {
      const fetchData = async () => {
        setLoadingProject(true);
        const { seaProject } = await projectApi.getProjectBySeaId(seaProjectId);
        setSeaProject(seaProject);
        setLoadingProject(false);
      };
      fetchData();
    }
  }, [ seaProjectId, seaProject ]);

  const getPrioritizedProject = async () => {
    try {
      const result = (await projectApi.getPriorityProject({ type: 'areas-of-influence' }))?.data;
      if (result) {
        const { seaProject } = result;
        setSeaProject(result.seaProject);
        if (!seaProject) {
          setLoadingProject(false);
        } else {
          setLoadingProject(false);
          replaceProjectToHistory(seaProject);
        }
      } else {
        setLoadingProject(false);
      }
    } catch (err) {
      setLoadingProject(false);
      if (err.serverMessage) {
        toast.error(err.serverMessage);
      }
      console.error(err);
      restartForm();
    }
  };

  const getRandomizedProject = async year => {
    setLoadingProject(true);
    setRandomProjectNotFound(false);
    try {
      const result = (await projectApi.getRandomProject({ year, type: typeKey }))?.data;
      const { seaProject } = result || {};
      if (!seaProject) {
        setRandomProjectNotFound(true);
        setLoadingProject(false);
      } else {
        setSeaProject(seaProject);
        setLoadingProject(false);
        pushProjectToHistory(seaProject);
      }
    } catch (err) {
      toast.error(err.serverMessage ?? err.serviceMessage ?? 'Hubo un error al pedir el proyecto. Por favor intenta más tarde');
      console.error(err);
      restartForm();
    }
  };

  const onChangeProjectYear = ({ projectYear }) => {
    setProjectYear(projectYear);
    getRandomizedProject(projectYear);
  };

  const openIssuesDialog = useCallback(() =>
    setDialog({ isOpen: true, type: 'report-issue', data: { seaProjectId }, actions: { closeDialog, restartForm } })
  , [ closeDialog, restartForm, seaProjectId ]);

  const removeComponent = useCallback(async ({ index, name, dataOnWeb }) => {
    // TODO: controlar error al eliminar
    if (dataOnWeb) {
      await areasOfInfluenceApi.deleteComponent({ seaProjectId, componentName: name });
    }
    setForm(pf => ({ ...pf, projectComponents: pf.projectComponents.filter((ac, currInd) => index !== currInd && ac.name !== name) }));
    availableComponentsRef.current[name] = true;
  }, [ seaProjectId ]);

  const openRemoveDialog = useCallback(({ index, name, dataOnWeb }) => setDialog({
    isOpen: true, type: 'remove-component',
    data: { componentName: name, componentIndex: index, dataOnWeb },
    actions: { closeDialog, removeComponent },
  }), [ closeDialog, removeComponent ]);

  const addComponent = useCallback(selectedComp => {
    if (availableComponentsRef.current[selectedComp.name]) {
      availableComponentsRef.current[selectedComp.name] = false;
      setForm(pf => ({ ...pf, projectComponents: [ ...pf.projectComponents, getEmptyAoIComponent(selectedComp) ] }));
    }
  }, []);

  const openAddComponent = useCallback(() => setDialog({
    isOpen: true, type: 'add-component',
    data: { componentList, availableOptions: availableComponentsRef.current },
    actions: { closeDialog, addComponent },
  }), [ closeDialog, addComponent, componentList ]);

  const updateComponentState = useCallback(({ index, update }) => setForm(ps => ({
    ...ps,
    projectComponents: ps.projectComponents.map((pc, i) =>
      index === i ? (
        typeof update === 'function' ?
          update(pc) // le pasa el componente actual a la función similar a como los setState pasan su estado actual cuando usas funciones
          : { ...pc, ...update }
      ) : pc,
    ),
  })), []);

  const [ errors, setErrors ] = useState({});

  // TODO: mover a un utils compartido entre flora y esto y futuros formularios?
  const makeErrors = (valErrors, arraySchemas) => {
    const errorObj = {};
    valErrors.inner.forEach(innerError => {
      const pathArr = innerError.path.match(/[^\][.]+/g);
      let currentPart = errorObj;
      pathArr.forEach((part, ind) => {
        if (currentPart[part] === undefined) {
          currentPart[part] = arraySchemas.includes(part) ? [] : {};
        }
        currentPart = currentPart[part];
        if (ind === pathArr.length - 1) {
          currentPart.errorMessage = innerError.message;
          currentPart.errorType = innerError.type;
          // made up field, useful when the error has additional data
          currentPart.errorExtra = innerError.params.errorExtra;
        }
      });
    });
    return errorObj;
  };

  const onSubmitGenerator = (noRestart = false) => async () => {
    if (sendingData || mustRestartPage) {
      return;
    }
    try {
      setErrors({});
      form.seaId = seaProjectId;
      await aoiSchema.validate(form, { abortEarly: false });
      setSendingData(true);
      toast.info('Guardando la información');
      form.seaId = seaProjectId;
      const formWithNewData = { ...form, projectComponents: projectComponents.filter(comp => !comp.dataOnWeb) };
      const { message } = await areasOfInfluenceApi.saveForm(formWithNewData);

      if (noRestart) {
        setForm(pf => ({ ...pf, projectComponents: pf.projectComponents.map(comp => ({ ...comp, dataOnWeb: true })) }));
      }
      lastSavedCommentsRef.current = form.comments;
      setSendingData(false);
      toast.dismiss();
      toast.success(message);
      if (!noRestart) {
        setMustRestartPage(true);
      }
    } catch (e) {
      toast.dismiss();
      setSendingData(false);
      if (e.name === 'ValidationError') {
        toast.error(<div>Hay problemas con el formulario.<br/>Por favor revisar</div>,
          { autoClose: 10000, allowHtml: true },
        );
        const formErrors = makeErrors(e, arraySchemaIds);
        console.error(`Problem submit form: ${e}`);
        console.error({ formErrors });
        setErrors(formErrors);
      } else {
        console.error(e);
        toast.error(e.serverMessage ?? e.serviceMessage ?? 'Ocurrió un error inesperado, por favor inténtalo más tarde');
      }
    }
  };

  const onSubmit = onSubmitGenerator(false);
  const onSubmitNoRestart = onSubmitGenerator(true);

  const onCommentUpdate = comments => setForm(pf => ({ ...pf, comments }));

  const { projectComponents } = form;
  return loadingProject ? <Loading/> : <>
    {!seaProject && <>
      <ProjectYearSelection formType={typeKey} projectYear={projectYear} updateState={onChangeProjectYear} />
      { randomProjectNotFound && <Typography variant="body1">No se encontró proyecto para el año seleccionado</Typography> }
    </>}
    { seaProject && (!loadedFormDataAndOptions ? <Loading/> : <>
      <Section title="Proyecto">
        { !mustRestartPage && <LeaveConfirm checkConfirmNeeded={checkLeaveConfirmNeeded}/> }
        <Box>
          <Typography variant="subtitle1" component="small" color="textSecondary">Año de presentación: { seaProject.year }</Typography>
        </Box>
        <Box>
          <Project project={seaProject} year={seaProject.year}></Project>
        </Box>
      </Section>
      <Page title={`Áreas de influencia - ${seaProject.nombre}`}>
        <Box display='flex' mt={ 4 } mb={ 1 } justifyContent='space-between'>
          <Typography variant="h5" component="span" gutterBottom>
            Áreas de Influencia
          </Typography>
          { errors?.projectComponents?.errorType === 'required' &&
            <Alert severity="error">{`${errors.projectComponents.errorMessage}`}</Alert>
          }
        </Box>
        <Box>
          <Button variant="contained" color="secondary" onClick={ openAddComponent }>
            Añadir Componente
          </Button>
        </Box>
        { !projectComponents.length ?
          <Box my={4}>
            <Typography>Usa el botón para añadir los componentes relevantes al proyecto</Typography>
          </Box>
          : projectComponents.map((comp, i) =>
            <ProjectComponent
              key={i}
              index={i}
              errors={errors?.projectComponents?.[i]}
              projectComponent={comp}
              updateComponentState={updateComponentState}
              phaseOptions={phaseOptions}
              getEmptyAreaGeometryGroup={getEmptyAreaGeometryGroup}
              openRemoveDialog={openRemoveDialog}
            />,
          )
        }
        <Section title="Observaciones">
          <Box>
            <TextField
              onChange={ e => onCommentUpdate(e.target.value)}
              type="text"
              name="comments"
              className={classes.comments}
              label="Observaciones"
              multiline
              minRows={4}
              placeholder="Escribe las consideraciones que tengan con relación al proyecto"
              variant="outlined"
              value={form.comments}
            />
          </Box>
        </Section>
        <Box display="flex" flex={2}>
          <Box>
            <Button className={classes.submitButton} variant="contained" onClick={openIssuesDialog} disabled={mustRestartPage}>
              No es posible llenar el formulario
            </Button>
          </Box>
          <Box mx={1.5}>
            <Button className={classes.submitButton} type="button" variant="contained" color="secondary"
              disabled={sendingData || mustRestartPage} onClick={onSubmitNoRestart} >
              Guardar progreso
            </Button>
          </Box>
          <Box>
            <Button className={classes.submitButton} type="button" variant="contained" color="primary"
              disabled={sendingData || mustRestartPage} onClick={onSubmit} >
              Enviar
            </Button>
          </Box>
        </Box>
        <DialogWrapper maxWidth='sm' fullWidth onClose={closeDialog} open={dialog.isOpen}>
          { dialog.isOpen && <DialogContents type={dialog.type} actions={dialog.actions} data={dialog.data} /> }
        </DialogWrapper>
      </Page>
    </>)}
  </>;
};


export { AreasOfInfluenceForm };
