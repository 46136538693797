import { get } from 'src/services/api';


const getPhases = async data => (await get('/options/phase', data)).data;
const getTemporalities = async data => (await get('/options/temporality', data)).data;


export {
  getPhases,
  getTemporalities,
};