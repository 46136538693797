import { get, post } from 'src/services/api';


const getListYear = async data => (await get('/project/years', data)).data;
const getListProjects = async data => (await get('/project/list', data)).data;
const saveProject = async data => await post('/project', data);
const getRandomProject = async data => await get('/project/random', data);
const getPriorityProject = async data => await get('/project/priority', data);
const getConsultantNames = async data => {
  const result = await get('/project/consultants-names', data);
  return result.data ? result.data : [];
};
const getProjectBySeaId = async seaProjectId => (await get(`/project/by-sea-id/${seaProjectId}`)).data;
const reportIssue = async data => await post('project/report-issue', data);


export {
  getListYear,
  saveProject,
  getConsultantNames,
  getProjectBySeaId,
  getRandomProject,
  getPriorityProject,
  reportIssue,
  getListProjects,
};