import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Typography, TextField } from '@material-ui/core';
import { ProjectYearSelection } from 'src/scenes/LoadProject/components';
import { toast } from 'react-toastify';
import { Alert } from '@material-ui/lab';

import Loading from 'src/components/Loading';
import { arraySchemaIds } from 'src/scenes/AreasOfInfluence/aoiValidationSchema';
import { Page, Section, Project } from 'src/components';
import { PaWContents } from 'src/scenes/PartsAndWorks/components';
import { projectApi, partsAndWorksApi, optionsApi } from 'src/services';


const typeKey = 'parts-and-works';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: 200,
    maxWidth: 300,
  },
  submitButton: {
    display: 'block',
    marginTop: theme.spacing(5),
  },
  comments: {
    width: '100%',
  },
}));

const getEmptyPawForm = () => ({
  geoJson: {
    features: [],
    type: 'FeatureCollection',
  },
  errors: [],
  empty: true,
  problemsDescription: '',
});

const PartsAndWorksForm = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const { seaProjectId } = match.params;

  const history = useHistory();
  const pushProjectToHistory = seaProject => history.push({
    pathname: `load-project/${seaProject.id}/form`,
    state: { seaProject: seaProject },
  });
  const replaceProjectToHistory = seaProject => history.replace({
    pathname: `load-project/${seaProject.id}/form`,
    state: { seaProject: seaProject },
  });

  const { state: locationState } = useLocation();
  const [ seaProject, setSeaProject ] = useState(locationState?.seaProject);
  const [ sendingData, setSendingData ] = useState(false);

  const [ form, setForm ] = useState({
    pawComponents: getEmptyPawForm(),
    comments: '',
  });
  const [ loadingProject, setLoadingProject ] = useState(!seaProject);
  const [ loadedFormDataAndOptions, setLoadedFormDataAndOptions ] = useState(false);
  const [ phaseOptions, setPhaseOptions ] = useState([]);
  const [ temporalityOptions, setTemporalityOptions ] = useState([]);
  const [ projectYear, setProjectYear ] = useState(seaProject?.year ?? null);
  const [ randomProjectNotFound, setRandomProjectNotFound ] = useState(false);

  const restartForm = useCallback(() => history.push('/app/parts-and_works/load-project'), [ history ]);

  const updatePawContent = useCallback(({ newData }) =>
    setForm(ps => ({
      ...ps,
      pawComponents: {
        ...newData,
        geoJson: {
          type: 'FeatureCollection',
          features: [ ...ps.pawComponents.geoJson.features, ...newData.geoJson.features ],
        },
      },
    }))
  , []);

  const setProblemDescription = useCallback(newDescription => setForm(ps => ({
    ...ps,
    pawComponents: {
      ...ps.pawComponents,
      problemsDescription: newDescription,
    },
  })), []);

  const deletePawContent = useCallback(() => setForm(ps => ({
    ...ps,
    pawComponents: getEmptyPawForm(),
  })), []);

  useEffect(() => {
    if (seaProjectId) {
      const fetchFormDataAndOptions = async () => {
        try {
          const [ phaseOptions, temporalityOptions ] = await Promise.all([
            optionsApi.getPhases({ component: 'paw' }),
            optionsApi.getTemporalities({ component: 'paw' }),
          ]);
          setTemporalityOptions(temporalityOptions);
          setPhaseOptions(phaseOptions);
          setLoadedFormDataAndOptions(true);
        } catch (e) {
          console.log(e);
          toast.error(e.serverMessage ?? 'Hubo un error al cargas los datos del formulario, por favor intenta más tarde');
        }
        setLoadingProject(false);
      };
      fetchFormDataAndOptions();
    } else {
      getPrioritizedProject();
    }
  // eslint-disable-next-line
  }, [ seaProjectId ]);

  useEffect(() => {
    if (seaProjectId && !seaProject) {
      const fetchData = async () => {
        setLoadingProject(true);
        const { seaProject } = await projectApi.getProjectBySeaId(seaProjectId);
        setSeaProject(seaProject);
        setLoadingProject(false);
      };
      fetchData();
    }
  }, [ seaProjectId, seaProject ]);

  const getPrioritizedProject = async () => {
    try {
      const result = (await projectApi.getPriorityProject({ type: 'parts-and-works' }))?.data;
      if (result) {
        const { seaProject } = result;
        setSeaProject(result.seaProject);
        if (!seaProject) {
          setLoadingProject(false);
        } else {
          setLoadingProject(false);
          replaceProjectToHistory(seaProject);
        }
      } else {
        setLoadingProject(false);
      }
    } catch (err) {
      setLoadingProject(false);
      if (err.serverMessage) {
        toast.error(err.serverMessage);
      }
      console.error(err);
      restartForm();
    }
  };

  const getRandomizedProject = async year => {
    setLoadingProject(true);
    setRandomProjectNotFound(false);
    try {
      const result = (await projectApi.getRandomProject({ year, type: typeKey }))?.data;
      const { seaProject } = result || {};
      if (!seaProject) {
        setRandomProjectNotFound(true);
        setLoadingProject(false);
      } else {
        setSeaProject(seaProject);
        setLoadingProject(false);
        pushProjectToHistory(seaProject);
      }
    } catch (err) {
      if (err.serverMessage) {
        toast.error(err.serverMessage);
      }
      console.error(err);
      restartForm();
    }
  };

  const onChangeProjectYear = ({ projectYear }) => {
    setProjectYear(projectYear);
    getRandomizedProject(projectYear);
  };

  const [ errors, setErrors ] = useState({});

  const makeErrors = (valErrors, arraySchemas) => {
    const errorObj = {};
    valErrors.inner.forEach(innerError => {
      const pathArr = innerError.path.match(/[^\][.]+/g);
      let currentPart = errorObj;
      pathArr.forEach((part, ind) => {
        if (currentPart[part] === undefined) {
          currentPart[part] = arraySchemas.includes(part) ? [] : {};
        }
        currentPart = currentPart[part];
        if (ind === pathArr.length - 1) {
          currentPart.errorMessage = innerError.message;
          currentPart.errorType = innerError.type;
          currentPart.errorExtra = innerError.params.errorExtra;
        }
      });
    });
    return errorObj;
  };

  const onSubmitGenerator = () => async () => {
    if (sendingData) {
      return;
    }
    try {
      setErrors({});
      setSendingData(true);
      toast.info('Guardando la información');
      form.seaId = seaProjectId;
      const bodyToSend = {};
      bodyToSend.seaId = seaProjectId;
      bodyToSend.comments = form.comments;
      bodyToSend.geoJson = {
        type: form.pawComponents.geoJson.type,
        features: form.pawComponents.geoJson.features,
      };
      bodyToSend.geomProblemsDescription = form.pawComponents.problemsDescription;
      const { message } = await partsAndWorksApi.saveForm(bodyToSend);
      toast.dismiss();
      toast.success(message);
      setSendingData(false);
      history.push('/app/parts-and-works/load-project');
    } catch (e) {
      toast.dismiss();
      setSendingData(false);
      if (e.name === 'ValidationError') {
        toast.error(<div>Hay problemas con el formulario.<br/>Por favor revisar</div>,
          { autoClose: 10000, allowHtml: true },
        );
        const formErrors = makeErrors(e, arraySchemaIds);
        console.error(`Problem submit form: ${e}`);
        console.error({ formErrors });
        setErrors(formErrors);
      } else {
        console.error(e);
        toast.error(e.serverMessage ?? e.message ?? 'Ocurrió un error inesperado, por favor inténtalo más tarde');
      }
    }
  };

  const onSubmit = onSubmitGenerator(false);
  const onCommentUpdate = comments => setForm(pf => ({ ...pf, comments }));

  const { pawComponents } = form;
  return loadingProject ? <Loading/> : <>
    {!seaProject && <>
      <ProjectYearSelection formType={typeKey} projectYear={projectYear} updateState={onChangeProjectYear} />
      { randomProjectNotFound && <Typography variant="body1">No se encontró proyecto para el año seleccionado</Typography> }
    </>}
    { seaProject && (!loadedFormDataAndOptions ? <Loading/> : <>
      <Section title="Proyecto">
        <Box>
          <Typography variant="subtitle1" component="small" color="textSecondary">Año de presentación: { seaProject.year }</Typography>
        </Box>
        <Box>
          <Project project={seaProject} year={seaProject.year}></Project>
        </Box>
      </Section>
      <Page title={`Partes y obras - ${seaProject.nombre}`}>
        { errors?.pawComponents?.errorType === 'required' &&
          <Alert severity="error">{`${errors.pawComponents.errorMessage}`}</Alert>
        }
        <PaWContents
          phaseOptions={phaseOptions.map(phases => phases.value)}
          pawContent={pawComponents}
          updatePawContent={updatePawContent}
          temporalityOptions={temporalityOptions.map(temporality => temporality.value)}
          deletePawContent={deletePawContent}
          setProblemDescription={setProblemDescription}
        />
        <Section title="Observaciones">
          <Box>
            <TextField
              onChange={ e => onCommentUpdate(e.target.value)}
              type="text"
              name="comments"
              className={classes.comments}
              label="Observaciones"
              multiline
              minRows={4}
              placeholder="Escribe las consideraciones que tengan con relación al proyecto"
              variant="outlined"
              value={form.comments}
            />
          </Box>
        </Section>
        <Box display="flex" flex={2}>
          <Box>
            <Button className={classes.submitButton} type="button" variant="contained" color="primary"
              disabled={(pawComponents.empty && !pawComponents.problemsDescription) || sendingData} onClick={onSubmit} >
              Enviar
            </Button>
          </Box>
        </Box>
      </Page>
    </>)}
  </>;
};


export { PartsAndWorksForm };
