import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Terrain, Satellite, Delete } from '@material-ui/icons';
import { LayersPlus } from 'src/utils/mdIcons';
import { renderToString } from 'react-dom/server';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-easybutton';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import JsZip from 'jszip';
import * as togeojson from '@tmcw/togeojson';
import PropTypes from 'prop-types';

import config from 'src/config/local';
import { isInvalidGeometry, objectIsEmpty } from 'src/utils';


const { satelliteMapUrl, outdoorMapUrl } = config;

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [ 25, 41 ],
  iconAnchor: [ 13, 41 ],
});

L.Marker.prototype.options.icon = DefaultIcon;

const useStyles = makeStyles({
  map: {
    width: '100%',
    height: '60vh',
  },
  iconStyle: {
    margin: '2px',
    color: '#484848',
  },
});

const Map = ({ nameId = 'map', importKml = false, geoJson, handleGeoJson, deleteGeoJson, allowMulti }) => {
  const classes = useStyles();
  const mapRef = useRef();
  const featureGroup = useRef();
  const deleteButtonRef = useRef();
  const [ deleteButtonVisible, setDeleteButtonVisible ] = useState(Boolean(geoJson && deleteGeoJson));

  const importElementId = `${nameId}-kml-import`;

  const handleKmlImport = async e => {
    const file = e.target.files[0];
    let kmlFile;
    if (file.type?.match(/\.kml/)) {
      kmlFile = file;
    } else if (file.type?.match(/\.kmz/)) {
      const kmzZip = await new JsZip().loadAsync(file);
      const kmlZip = kmzZip.filter(relativePath => relativePath.match(/\.kml$/))[0];
      kmlFile = await kmlZip.async('blob');
    } else {
      return console.error('Solo se aceptan archivos .kml y .kmz');
    }
    const kmlAsText = await new Response(kmlFile).text();
    const kmlAsGeoJson = togeojson.kml(new DOMParser().parseFromString(kmlAsText, 'text/xml'));

    handleGeoJson({ geoJson: kmlAsGeoJson, importFromKml: true, kmlFileName: kmlFile.name });

    const areaImportElement = document.getElementById(importElementId);
    if (areaImportElement) {
      areaImportElement.value = '';
    }
  };

  useEffect(() => {
    featureGroup.current = new L.featureGroup();
    const satelliteLayer = new L.tileLayer(satelliteMapUrl, {});
    const outdoorLayer = new L.tileLayer(outdoorMapUrl, {});

    const kmlAreaInput = document.getElementById(importElementId);

    mapRef.current = new L.map(nameId, {
      center: [ -33.4679997, -70.7003513 ],
      zoom: 4,
      layers: [ satelliteLayer ],
    });

    const layerButtons = L.easyButton({
      id: `${nameId}-base-layer`,
      position: 'topright',
      type: 'replace',
      states: [ {
        stateName: 'satelital-layer',
        onClick: (btn, map) => {
          if (satelliteLayer) {
            map.removeLayer(satelliteLayer);
          }
          outdoorLayer.addTo(map);
          btn.state('street-layer');
        },
        title: 'Cambiar a mapa topográfico',
        icon: renderToString(<Terrain fontSize="small" className={classes.iconStyle} />),
      }, {
        stateName: 'street-layer',
        onClick: (btn, map) => {
          if (outdoorLayer) {
            map.removeLayer(outdoorLayer);
          }
          satelliteLayer.addTo(map);
          btn.state('satelital-layer');
        },
        title: 'Cambiar a mapa satélital',
        icon: renderToString(<Satellite fontSize="small" className={classes.iconStyle} />),
      } ],
    });

    if (importKml) {
      const kmlImportAreaButton = L.easyButton({
        id: `${nameId}-import-area`,
        position: 'topleft',
        type: 'replace',
        states: [ {
          onClick: () => kmlAreaInput.click(),
          title: 'Importar kml o kmz del proyecto',
          icon: renderToString(<LayersPlus fontSize="small" className={classes.iconStyle} />),
        } ],
      });

      kmlImportAreaButton.addTo(mapRef.current);
    }

    layerButtons.addTo(mapRef.current);
  }, [ nameId, importKml, classes, importElementId ]);

  // crear botón de borrado
  useEffect(() => {
    if (deleteGeoJson && !deleteButtonRef.current) {
      deleteButtonRef.current = L.easyButton({
        id: `${nameId}-delete-layers`,
        position: 'topleft',
        type: 'replace',
        states: [ {
          onClick: deleteGeoJson,
          title: 'Borrar geometrías importadas',
          icon: renderToString(<Delete fontSize="small" className={classes.iconStyle} />),
        } ],
      });

      deleteButtonRef.current.addTo(mapRef.current);
      if (!geoJson) {
        // esconder si no hay geoJson (esto le pone una clase que se define en index.css)
        deleteButtonRef.current.disable();
      }
    }
  }, [ nameId, classes.iconStyle, deleteGeoJson, geoJson ]);

  // activar o desactivar botón de borrado si hay función de borrado y el ref está creado
  useEffect(() => {
    if (deleteGeoJson && Boolean(deleteButtonRef.current)) {
      if (!geoJson && deleteButtonVisible) {
        deleteButtonRef.current.disable();
        setDeleteButtonVisible(false);
      } else if (geoJson && !deleteButtonVisible) {
        deleteButtonRef.current.enable();
        setDeleteButtonVisible(true);
      }
    }
  }, [ geoJson, deleteGeoJson, deleteButtonVisible ]);

  useEffect(() => {
    const drawGroup = featureGroup.current;

    drawGroup.clearLayers();

    const geoJsonFeature = L.geoJson(geoJson, {
      filter: feature => !isInvalidGeometry(feature, { allowMulti }),
    });

    drawGroup.addLayer(geoJsonFeature);
    drawGroup.addTo(mapRef.current);
    if (objectIsEmpty(geoJsonFeature._layers)) {
      mapRef.current.setZoom(4);
    } else {
      const bounds = drawGroup.getBounds();
      mapRef.current.fitBounds(bounds);
    }
  }, [ geoJson, allowMulti ]);

  return (
    <div id={nameId} className={classes.map}>
      {importKml &&
        <input id={importElementId} type="file" accept=".kml, .kmz" onChange={handleKmlImport} style={{ display: 'none' }} />
      }
    </div>
  );
};

Map.propTypes = {
  nameId: PropTypes.string,
  importKml: PropTypes.bool,
  geoJson: PropTypes.object,
  handleGeoJson: PropTypes.func,
  deleteGeoJson: PropTypes.func,
  allowMulti: PropTypes.bool,
};


export default Map;
